import SearchIcon from '@mui/icons-material/Search'
import {
  Checkbox,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useMemo, useState } from 'react'
import './style.scss'
import { containsText } from 'features/history/services/filter'

type Props = {
  defaultValue: string[];
  onChange: (values: string[]) => void
  disabled: boolean;
  options: {label: string, value: string}[];
}

export default function SearchSelect({
  onChange, defaultValue, disabled, options,
}: Props) {
  const [selectedOption, setSelectedOption] = useState(defaultValue)
  const [searchText, setSearchText] = useState('')
  const displayedOptions = useMemo(
    () => options.filter(option => containsText(option.label, searchText)),
    [searchText],
  )

  const handleChange = (values: string[]) => {
    setSelectedOption(values)
  }

  const onClose = () => {
    setSearchText('')
    onChange(selectedOption)
  }
  return (
    <Select
      onClose={onClose}
      MenuProps={{ autoFocus: false, className: 'search-select-menu' }}
      labelId="search-select-label"
      id="search-select"
      multiple
      disabled={disabled}
      value={selectedOption}
      onChange={e => handleChange(e.target.value as string[])}
      renderValue={() => selectedOption.map(opt => options.find(o => o.value === opt).label).join(', ')}
    >
      <ListSubheader>
        <TextField
          size="small"
          autoFocus
          placeholder="Rechercher..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => setSearchText(e.target.value)}
          onKeyDown={e => {
            if (e.key !== 'Escape') {
              e.stopPropagation()
            }
          }}
        />
      </ListSubheader>
      {displayedOptions.map(option => (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox checked={selectedOption.indexOf(option.value) > -1} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </Select>
  )
}
