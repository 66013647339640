import NotStartedIcon from '@mui/icons-material/NotStarted'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import IconButton from '@mui/material/IconButton'
import Slider from '@mui/material/Slider'
import { signal } from '@preact/signals-react'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { resetSimulation } from 'reducers/simulations/slices'
import { SocketMessage } from 'types/websocket'
import './style.scss'
import SimpleModal from 'components/modals/simpleModal/SimpleModal'
import SimpleButton from 'components/button/SimpleButton'
import Clock from 'features/logs/components/clock/Clock'

export const speedSignal = signal<number>(1)

export default function TimeController() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const {
    webSocket, displayComments, paused, started,
  } = useAppSelector(state => state.simulations)
  const [openModal, setOpenModal] = useState(false)
  const [queryParams] = useSearchParams()

  const isHistory = queryParams.get('mode') === 'history'

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const sendMessage = (type: string) => {
    webSocket?.current?.send(JSON.stringify({
      messageType: type,
      pause: !paused,
    }))
  }

  useEffect(() => {
    if (displayComments && !isHistory && webSocket && started) {
      sendMessage(SocketMessage.pause)
    }
  }, [displayComments])

  const marks = [

    {
      value: 50,
      label: 'x50',
    },
  ]

  const handleSendClick = (type: string) => {
    sendMessage(type)
  }

  const handleConfirmModal = () => {
    navigate('/')
    if (webSocket?.current) {
      sendMessage(SocketMessage.stop)
    }
    dispatch(resetSimulation())
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    webSocket.current.send(JSON.stringify({
      messageType: SocketMessage.speed,
      speed: newValue as number,
    }))
    speedSignal.value = newValue as number
  }

  const handleSpeedChange = (event: Event, newValue: number | number[]) => {
    speedSignal.value = newValue as number
  }

  const getPauseIcon = () => {
    if (typeof paused !== 'boolean') {
      return <NotStartedIcon fontSize="inherit" />
    }
    return !paused ? <PauseCircleIcon fontSize="inherit" /> : <PlayCircleIcon fontSize="inherit" />
  }

  return (
    <div className={`time-controller ${displayComments || isHistory ? 'd-none' : ''}`}>
      <SimpleModal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={handleConfirmModal}
        title="Souhaitez-vous vraiment quitter la simulation?"
        confirmBtn="Quitter"
      />
      <SimpleButton
        text="Démarrer"
        onClick={() => handleSendClick('START')}
        disabled={started === undefined || started || !webSocket}
        sx={{ marginRight: '1rem' }}
      />
      <IconButton
        aria-label="pause"
        sx={{ fontSize: '2.75rem' }}
        onClick={() => handleSendClick('PAUSE')}
        color="primary"
        disabled={!started || typeof paused !== 'boolean'}
      >
        {getPauseIcon()}
      </IconButton>
      <IconButton aria-label="stop" sx={{ fontSize: '2.75rem' }} onClick={() => setOpenModal(true)}>
        <StopCircleIcon fontSize="inherit" color="error" />
      </IconButton>
      <Clock />
      <div className="slider-wrapper">
        <div>Vitesse actuelle </div>
        <Slider
          className="slider"
          value={speedSignal.value}
          aria-label="slider"
          valueLabelFormat={(value: number) => `x${value}`}
          valueLabelDisplay="on"
          step={1}
          min={1}
          disabled={!webSocket}
          max={50}
          marks={marks}
          onChange={handleSpeedChange}
          onChangeCommitted={handleChange}
        />
      </div>

    </div>
  )
}
