import { Dialog, TextField } from '@mui/material'
import { ReactElement, useState } from 'react'
import './Student.scss'
import { useAppDispatch } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { createSimulationFromDashboard } from 'reducers/student/thunks'
import SimpleButton from 'components/button/SimpleButton'

export default function Student(): ReactElement {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [code, setCode] = useState('')

  const joinRoom = () => {
    dispatch(createSimulationFromDashboard(code)).unwrap().then((res: any) => {
      navigate(`/logs/${res.payload.id}`)
    })
      .catch(() => undefined)
  }
  return (
    <Dialog
      className="student-modal"
      open
    >
      <div className="div">
        Veuillez entrer un code pour rejoindre une classe
        <TextField value={code} onChange={evt => setCode(evt.target.value)} />

        <SimpleButton text="Rejoindre" onClick={joinRoom} />
      </div>

    </Dialog>
  )
}
