import { auth } from '@osrdata/app_core'
import '@preact/signals-react/auto'
import { terms } from 'assets/localize/terms'
import TabList from 'components/tabList/TabList'
import ModalWrapper, { modalSignal } from 'components/modals/wrapper'
import { useAppDispatch, useAppSelector } from 'hooks'

import { Loader, TopBar } from 'components'
import CustomSnackbar from 'components/snackbar/CustomSnackbar'
import AccessDenied from 'pages/accessDenied/AccessDenied'
import History from 'pages/history/History'
import Home from 'pages/home/Home'
import Interface from 'pages/interface/Interface'
import Logs from 'pages/logs/Logs'
import RoleSelection from 'pages/roleSelection/RoleSelection'
import Snci from 'pages/snci/Snci'
import Student from 'pages/student/Student'
import Trainer from 'pages/trainer/Trainer'
import Tst from 'pages/tst/Tst'
import {
  ReactElement, StrictMode, Suspense, useEffect,
} from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import { hideSnackbar } from 'reducers/app'
import { setTcoOpen, setTptOpen } from 'reducers/interfaces/slices'
import {
  isDependantWindow, isSnciWindow, isTcoWindow, isTptWindow,
} from 'services/route'
import { isDevEnv } from 'utils'
import { navTabElements, tcoTabElement } from 'utils/navTabElements'
import './App.scss'

const APP_NAME = 'masters'
const ACCESS_PERM = 'masters::ACCESS'

/** Externalize router logic in its own component
 * in order to use useLocation hook
 */
function Router() {
  const location = useLocation()

  useEffect(() => {
    // force hiding modal on route change
    modalSignal.value = undefined
  }, [location.pathname])

  const customRoutes = isDevEnv() ? (
    <>
      <Route element={<RoleSelection />} path="/" />
      <Route element={<Home />} path="/admin" />
      <Route element={<Student />} path="/eleve" />
      <Route element={<Trainer />} path="/dashboard/:id" />
      <Route element={<Home />} path="/formateur" />
    </>
  ) : (
    <Route element={<Home />} path="/" />
  )
  return (
    <Routes>
      {customRoutes}
      <Route element={<Logs />} path="/logs/:id?" />
      <Route element={<History />} path="/history" />
      <Route element={<Interface />} path="/:interface/:id/:mode?" />
      <Route element={<Snci />} path="/snci/:id" />
      <Route element={<Tst />} path="/tst/:id" />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { isLogged, appPermissions, isLoading } = useAppSelector(state => state.user)
  const { displaySnackbar, snackbarMessage, snackbarSeverity } = useAppSelector(state => state.app) || {}
  const { selectedSimulation } = useAppSelector(state => state.simulations)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())

    if (isTcoWindow()) {
      dispatch(setTcoOpen(true))
      return
    }
    if (isTptWindow()) {
      dispatch(setTptOpen(true))
    }
  }, [])

  const renderApp = () => {
    if (isLoading || !isLogged) return <Loader message={terms.Common.loading} standalone />
    if (!appPermissions.includes(ACCESS_PERM) && isLogged && !isLoading) return <AccessDenied />

    return (
      <>
        <Router />
        <ModalWrapper />
      </>
    )
  }

  const getCloseButtonText = () => {
    if (isTcoWindow()) return terms.Tco.close
    if (isSnciWindow()) return terms.Snci.close
    return ''
  }

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <TopBar
            appName={APP_NAME}
            innerComponent={(
              <TabList
                tabs={isDependantWindow()
                  ? tcoTabElement(selectedSimulation?.name || '', window.location.href) : navTabElements}
                isDependantWindow={isDependantWindow()}
              />
            )}
            hasAccess={!isLoading && isLogged && appPermissions.includes(ACCESS_PERM)}
            isDependantWindow={isDependantWindow()}
            displayCloseButton={isTcoWindow() || isSnciWindow()}
            closeButtonText={getCloseButtonText()}
          />
          <div id="app">{renderApp()}</div>
          <CustomSnackbar
            message={snackbarMessage}
            severity={snackbarSeverity}
            displaySnackbar={displaySnackbar}
            handleClose={() => dispatch(hideSnackbar())}
          />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}
