import { LongBeep, ShortBeep } from 'reducers/types'
import { SocketMessage } from 'types/websocket'

export const isLongBeep = (bp: ShortBeep | LongBeep): bp is LongBeep => bp.messageType === SocketMessage.longBeep

export const isShortBeep = (bp: ShortBeep | LongBeep): bp is ShortBeep => bp.messageType === SocketMessage.shortBeep

export const formatToReadablePk = (pk: string | undefined) => pk?.replace('_', ',')

export const formatToDataPk = (pk: string) => pk.replace(',', '_')
