/* eslint-disable max-len */
import {
  get,
} from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import {
  setObjects,
} from './slices'

const getNetworkObjects = (id: string) => createApiThunk(
  'networks/getObjects',
  () => get(`/masters/networks/${id}/objects`),
  setObjects,
)

export {
  getNetworkObjects,
}
