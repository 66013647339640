import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import SimpleButton, { ButtonStyle } from 'components/button/SimpleButton'
import { useAppDispatch, useAppSelector } from 'hooks'
import moment from 'moment'
import {
  useEffect,
} from 'react'
import { useParams } from 'react-router-dom'
import { getSimulation } from 'reducers/simulations/thunks'
import { resetTstTableData } from 'reducers/snci/slices'
import { downloadTst, getTstTable } from 'reducers/snci/thunks'
import { RootState } from 'reducers/store'
import { UrlParams } from 'reducers/types'
import { savefile } from 'utils'
import './style.scss'

export default function Tst() {
  const { selectedSimulation } = useAppSelector((state: RootState) => state.simulations)
  const { tstTable, tstTableData } = useAppSelector(state => state.snci)
  const urlParams = useParams<UrlParams>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (urlParams.id) {
      dispatch(getSimulation(urlParams.id))
    }
  }, [])

  useEffect(() => {
    if (selectedSimulation) {
      dispatch(getTstTable(selectedSimulation.scheduleId))
    }
  }, [selectedSimulation])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: tstTable?.default_column_color || '#212731',
      color: theme.palette.common.white,
      borderRight: '1px solid #EEF3FB',
      borderTop: '1px solid #EEF3FB',
      borderBottom: '1px solid #EEF3FB',
      '&:last-of-type': {
        borderRight: 0,
      },

    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }))

  const handleDownload = () => () => {
    if (selectedSimulation) {
      dispatch(downloadTst(selectedSimulation.scheduleId))
    }
  }

  useEffect(() => {
    if (tstTableData) {
      savefile(tstTableData, `TST_${moment().format('DD/MM/YYYY')}`)
    }
    dispatch(resetTstTableData())
  }, [tstTableData])

  return (
    <div className="tst">
      <div className="toolbar">
        <SimpleButton
          style={ButtonStyle.purple}
          onClick={handleDownload()}
          text="Télécharger"
          sx={{ padding: '10px 24px 8px !important' }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tstTable?.columns?.map(header => (
                <StyledTableCell
                  key={header.name}
                  align="center"
                  colSpan={header.columns ? header.columns.length : 1}
                  rowSpan={header.columns ? 1 : 2}
                  style={{
                    paddingLeft: header.columns ? 0 : '16px',
                    paddingRight: header.columns ? 0 : '16px',
                    paddingTop: '0',
                    paddingBottom: '0',
                  }}
                >
                  <div
                    style={{
                      padding: '4px 0',
                      borderBottom: header.columns ? '1px solid #EEF3FB' : 'none',
                    }}
                  >
                    {header.name}
                  </div>

                  {header.columns && (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(${header.columns.length}, 1fr)`,
                    }}
                  >
                    {header.columns.map((subColumn, index) => (
                      <div
                        style={{
                          borderRight: index !== header.columns.length - 1 ? '1px solid #EEF3FB' : 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '4px 0px',
                        }}
                        key={subColumn.name}
                      >
                        {subColumn.name}
                      </div>
                    ))}
                  </div>
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tstTable?.rows?.map((row, rowIndex) => {
              let rowDataIndex = 0
              const rowColor = rowIndex % 2 === 0 ? tstTable.default_row_colors[0]
                : tstTable.default_row_colors[1]

              return (
                <StyledTableRow style={{ height: '1px' }} key={row.join('-')}>
                  {tstTable?.columns?.map((column, columnIndex) => {
                    // if column has subColumns
                    if (column.columns) {
                      return (
                        <StyledTableCell
                          key={column.name}
                          align="left"
                          colSpan={column.columns.length}
                          rowSpan={1}
                          style={{
                            paddingLeft: column.columns ? 0 : '16px',
                            paddingRight: column.columns ? 0 : '16px',
                            paddingTop: '0',
                            paddingBottom: '0',
                            borderBottom: '1px solid #EEF3FB',
                            backgroundColor: rowColor,
                            borderRight: '1px solid #EEF3FB',
                            height: 'inherit',
                          }}
                        >

                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: `repeat(${column.columns.length}, 1fr)`,
                              height: '100%',
                            }}
                          >
                            {column.columns.map((subColumn, subIndex) => {
                              const subCell = row[rowDataIndex]
                              rowDataIndex += 1
                              return (
                                <div
                                  style={{
                                    borderRight: subIndex !== column.columns.length - 1 ? '1px solid #EEF3FB' : 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  key={subColumn.name}
                                >
                                  {subCell}
                                </div>
                              )
                            })}
                          </div>
                        </StyledTableCell>
                      )
                    }
                    // no subColumns
                    const relativeWidth = column.relative_width || 1
                    const cell = row[rowDataIndex]
                    rowDataIndex += 1
                    return (
                      <StyledTableCell
                        key={column.name}
                        colSpan={column.relative_width || 1}
                        align="left"
                        style={{
                          width: `${(relativeWidth
                          / tstTable.columns.reduce((acc, col) => acc + (col.relative_width || 1), 0)) * 100}vw`,
                          borderRight: '1px solid #EEF3FB',
                          borderBottom: '1px solid #EEF3FB',
                          whiteSpace: 'pre',
                          backgroundColor: column.row_colors ? column.row_colors[0] : rowColor,
                        }}
                      >
                        {cell}
                      </StyledTableCell>
                    )
                  })}
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
