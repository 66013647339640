import { Autocomplete } from '@mui/material'
import terms from 'assets/localize/terms'
import TextInput from 'components/textInput/TextInput'
import { findOccurrences } from 'services/string'
import './Autocomplete.scss'

type Props = {
  objects: string[];
  value: string;
  onValueChange: (val: string) => void
  placeholder?: string;
  disabled?: boolean;
  clearable?: boolean;
}

const defaultProps = {
  placeholder: '',
  disabled: false,
  clearable: false,
}

function CustomAutocomplete({
  objects, value, onValueChange, placeholder, disabled, clearable,
}: Props) {
  return (
    <Autocomplete
      className="custom-autocomplete"
      disabled={disabled}
      options={objects}
      value={value || null}
      getOptionLabel={option => option}
      onChange={(_, v) => onValueChange(v)}
      renderInput={params => (
        <TextInput
          {...params}
          placeholder={placeholder}
          value={value}
          onChange={v => onValueChange(v)}
        />
      )}
      noOptionsText={terms.Common.noResults}
      clearText={terms.Common.clear}
      disableClearable={!clearable}
      forcePopupIcon={false}
      renderOption={(props, option, { inputValue }) => {
        const matches = findOccurrences(option, inputValue)

        return (
          <li key={crypto.randomUUID()} {...props}>
            <div>
              {matches.map(part => (
                <span
                  key={crypto.randomUUID()}
                  style={{
                    fontWeight: part.match ? 700 : 400,
                  }}
                >
                  {part.segment}
                </span>
              ))}
            </div>
          </li>
        )
      }}
    />
  )
}

CustomAutocomplete.defaultProps = defaultProps

export default CustomAutocomplete
