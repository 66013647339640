export enum SocketMessage {
  geojson = 'GEOJSON_PATCH',
  time = 'TIME',
  svg = 'SVG_PATCH',
  pause = 'PAUSE',
  stop = 'STOP',
  start = 'START',
  interaction = 'INTERACTION',
  speed = 'SPEED',
  keyPress = 'KEY_PRESS',
  clearLogs = 'CLEAR_LOGS',
  forcePause = 'FORCE_PAUSE',
  finalRecordedTime = 'FINAL_RECORDED_TIME',
  longBeep = 'LONG_BEEP',
  shortBeep = 'SHORT_BEEP',
  click = 'CLICK',
  clickabilityForm = 'CLICKABILITY_FORM',
  trainManager = 'TRAIN_MANAGER_STATE',
  trainOnSight = 'ON_SIGHT',
}

export enum TrainManagerSocketMessage {
  trainList = 'TRAIN_LIST',
  trainDescription = 'TRAIN_DESCRIPTION',
  trainStaticDescription = 'TRAIN_STATIC_DESCRIPTION',
  trainDynamicDescription = 'TRAIN_DYNAMIC_DESCRIPTION',
  empty = 'EMPTY',
  emergencyStop = 'EMERGENCY_STOP',
  stopAtPk = 'STOP_AT_PK',
  stopAtSignal = 'STOP_AT_SIGNAL',
  driverCommunication = 'TRAIN_DRIVER_COMMUNICATION',
  pkError = 'PK_ERROR',
  stopCancellation = 'STOP_CANCELLATION',
  movingWithPk = 'MOVING_AUTHORIZATION_WITH_PK',
  moving = 'MOVING_AUTHORIZATION',
  trainPk = 'TRAIN_PK',
  crossingAuthorization = 'CROSSING_AUTHORIZATION',
  resumeNormalOperation = 'RESUME_NORMAL_OPERATION'
}
export enum Interaction {
  toggle = 'TOGGLE',
}

type FormAction = {
  disturbanceId: string
  disturbanceName: string
  disturbanceType: string
  available: boolean
}

export type InteractionForm = {
  automatonVerboseName: string
  automatonName: string
  actions: FormAction[]
}
