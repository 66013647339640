import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { NetworkObject } from 'types/network'

export type NetworksState = {
 objects: NetworkObject;
}

const initialState: NetworksState = {
  objects: { signals: [] },
}

const networkSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {
    setObjects: (state, action: PayloadAction<NetworkObject>) => {
      state.objects = action.payload
    },
  },

})

export const {
  setObjects,

} = networkSlice.actions

export default networkSlice.reducer
