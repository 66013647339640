export type Train = {
  index: string;
  id: string;
}

export enum SightState {
  onSight = 'ON_SIGHT',
  notOnSight = 'NOT_ON_SIGHT',
  transitionToSight = 'TRANSITION_TO_ON_SIGHT'
}

export enum Direction {
  ascending = 'ASCENDING',
  descending = 'DESCENDING'
}

export type TrainDetails = {
  trainIndex: string;
  onSightState: SightState;
  pk: string;
  trainId: number;
  speed: number;
  direction: string;
  rollingStock: string;
  observation: string;
  traction: string;
  destination: string;
  composition: string;
  compositionDetail: string;
  length: number;
}

export type DriverCommunication = {
  crossingAuthorizationVisibility: boolean;
  currentConditionsVisibility: boolean;
  trainId: number;
  trainIndex: string;
  movingAuthorizationVisibility: boolean;
  stopPK: string | null;
  stopSignal: string | null;
  emergencyStopVisibility: boolean;
}

export type PKError = {messageType: string, pk: string}
