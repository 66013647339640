import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage
import { auth } from '@osrdata/app_core'
import appReducer from './app'
import simulationReducer from './simulations/slices'
import userInfoReducer from './userInfo/slices'
import snciReducer from './snci/slices'
import interfaceReducer from './interfaces/slices'
import trainerReducer from './trainer/slices'
import studentReducer from './student/slices'
import networkReducer from './networks/slices'

const userReducer = auth.reducer

const userWhiteList = [
  'account',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user'],
}

export const rootReducer = {
  user: userReducer,
  app: appReducer,
  simulations: simulationReducer,
  userinfo: userInfoReducer,
  interfaces: interfaceReducer,
  snci: snciReducer,
  trainer: trainerReducer,
  student: studentReducer,
  networks: networkReducer,
}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
