import { signal } from '@preact/signals-react'
import { ReactComponent as SncfReseauIcon } from 'assets/logos/reseau.svg'
import { modalSignal } from 'components/modals/wrapper'
import { ReactNode } from 'react'

import './style.scss'
import SimpleButton, { ButtonStyle } from 'components/button/SimpleButton'
import ConfirmPopup from 'components/confirmPopup/ConfirmPopup'
import AccountMenu from './menu'

type Props = {
  appName: string;
  innerComponent?: ReactNode;
  hasAccess: boolean;
  isDependantWindow: boolean;
  displayCloseButton: boolean;
  closeButtonText: string;
}

const defaultProps = {
  innerComponent: <div />,
}

export const topBarTitle = signal<string>('')

export default function TopBar({
  appName, innerComponent, hasAccess, isDependantWindow, displayCloseButton, closeButtonText,
}: Props) {
  const getMenu = () => {
    if (isDependantWindow && displayCloseButton) {
      return (
        <SimpleButton
          style={ButtonStyle.purple}
          text={closeButtonText}
          onClick={() => {
            if (isDependantWindow && window.location.href.includes('static')) {
              window.close()
              return
            }
            modalSignal.value = <ConfirmPopup />
          }}
        />
      )
    }
    return <AccountMenu />
  }

  return (
    <header>
      <div className="logos">
        <SncfReseauIcon />
        <h1>{appName}</h1>
      </div>
      <div className="absolute-center">{hasAccess && innerComponent}</div>
      <div />
      {getMenu()}
    </header>
  )
}

TopBar.defaultProps = defaultProps
