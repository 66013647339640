import DeleteIcon from '@mui/icons-material/Delete'
import {
  Button,
  ClickAwayListener, IconButton, MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import { signal } from '@preact/signals-react'
import terms from 'assets/localize/terms'
import DateRangeFilter from 'components/daterangeFilter/DateRangeFilter'
import { useAppSelector } from 'hooks'
import { debounce } from 'lodash'
import { Moment } from 'moment'
import { ReactElement, useEffect, useState } from 'react'
import { setFilters } from 'reducers/simulations/slices'
import { RootState, store } from 'reducers/store'
import { getOperators, HISTORY_COLUMNS } from 'features/history/services/filter'
import { Filter, FilterOperators, FilterWithId } from 'types/filters'
import './style.scss'
import SearchSelect from 'components/searchSelect/SearchSelect'
import SelectCheckbox from 'components/selectCheckbox/SelectCheckbox'
import TextInput from 'components/textField/TextInput'
import DateInput from 'components/datepicker/DateInput'

export const FilterSignal = signal<boolean>(false)
const debouncedFilter = debounce(
  (val: Filter[]) => store.dispatch(setFilters(val)),
  500,
)

export default function Filters(): ReactElement {
  const { filters, commentSeverityList } = useAppSelector((state: RootState) => state.simulations)
  const { usersInfo } = useAppSelector((state: RootState) => state.userinfo)
  const [filtersState, setFiltersState] = useState<FilterWithId[]>(filters.map(
    filter => ({ ...filter, id: crypto.randomUUID() }),
  ))
  const {
    commentStatusList,
  } = useAppSelector(state => state.simulations)

  const handleAddFilter = () => {
    setFiltersState([...filtersState, {
      onField: HISTORY_COLUMNS[0].value,
      operator: getOperators(HISTORY_COLUMNS[0].value)[0],
      value: '',
      id: crypto.randomUUID(),
    }])
  }

  useEffect(() => {
    if (filters.length) return
    handleAddFilter()
  }, [])

  const handleFieldChange = (onField: string, id: string) => {
    const newOperators = getOperators(onField)

    let operator = filtersState.find(f => f.id === id)?.operator
    if (!newOperators.includes(operator)) {
      [operator] = newOperators
    }

    setFiltersState(filtersState.map(
      filter => (filter.id === id ? {
        ...filter, onField, operator, value: '',
      } : filter),
    ))
  }

  const updateFilters = (filt: FilterWithId[]) => {
    setFiltersState(filt)
    debouncedFilter(filt)
  }

  const handleOperatorChange = (id: string) => (event: SelectChangeEvent<FilterOperators>) => {
    const operator = event.target.value as FilterOperators
    const oldFilter = filtersState.find(f => f.id === id)
    let newValue = oldFilter.value

    if (oldFilter.operator === FilterOperators.between || operator === FilterOperators.between) {
      newValue = ''
    }

    updateFilters(filtersState.map(
      filter => (filter.id === id ? { ...filter, operator, value: newValue } : filter),
    ))
  }

  const handleValueChange = <T, >(id: string) => (value: T) => {
    updateFilters(filtersState.map(
      filter => (filter.id === id ? { ...filter, value: (value as T[])?.length ? value : '' } : filter),
    ))
  }

  const handleRemoveFilter = (id: string) => {
    if (filtersState.length === 1) {
      FilterSignal.value = undefined
    }

    updateFilters(filtersState.flatMap(
      filter => (filter.id === id ? [] : filter),
    ))
  }

  const missingFilterValue = filtersState.some(filter => filter.value === ''
    && (filter.operator !== FilterOperators.null && filter.operator !== FilterOperators.notNull))

  const getValueInput = (filter: FilterWithId) => {
    const { onField, operator } = filter
    const inputDisabled = operator === FilterOperators.null || operator === FilterOperators.notNull
    if (onField === 'createdAt') {
      if (operator === FilterOperators.between) {
        return (
          <DateRangeFilter
            defaultValue={filter.value as Moment[]}
            onChange={handleValueChange(filter.id)}
          />
        )
      }
      return (<DateInput defaultValue={filter.value as string} onChange={handleValueChange(filter.id)} />)
    }
    if (onField === 'userId') {
      return (
        <SearchSelect
          options={usersInfo.map(u => ({ label: u.username.toUpperCase(), value: u.id }))}
          defaultValue={filter.value ? filter.value as string[] : []}
          onChange={handleValueChange(filter.id)}
          disabled={inputDisabled}
        />
      )
    }
    if (onField === 'status' || onField === 'severity') {
      const options = onField === 'status'
        ? commentStatusList.map(status => ({ label: status.content, value: status.level }))
        : commentSeverityList.map(criticity => ({ label: criticity.content, value: criticity.level }))

      return (
        <SelectCheckbox
          disabled={inputDisabled}
          defaultValue={filter.value ? filter.value as string[] : []}
          options={options}
          onChange={handleValueChange(filter.id)}
        />
      )
    }
    return <TextInput disabled={inputDisabled} value={filter.value as string} onChange={handleValueChange(filter.id)} />
  }

  return (
    <ClickAwayListener mouseEvent="onMouseUp" onClickAway={() => { FilterSignal.value = false }}>
      <div className="filters">
        {filtersState.map(filter => (
          <div key={filter.id} className="rows">
            <IconButton
              onClick={() => handleRemoveFilter(filter.id)}
            >
              <DeleteIcon />
            </IconButton>
            <div className="ml-3 param">
              <div className="input-title">{terms.Filter.column}</div>
              <Select
                value={filter.onField}
                onChange={evt => handleFieldChange(evt.target.value, filter.id)}
                sx={{ background: 'white' }}
              >

                {HISTORY_COLUMNS.map(column => (
                  <MenuItem key={column?.value} value={column?.value}>{column?.label}</MenuItem>
                ))}
              </Select>
            </div>

            <div className="ml-3 param">
              <div className="input-title">{terms.Filter.operator}</div>
              <Select<FilterOperators>
                value={filter.operator}
                onChange={handleOperatorChange(filter.id)}
                sx={{ background: 'white' }}
              >

                {getOperators(filter.onField).map(key => (
                  <MenuItem key={key} value={key}>{terms.Filter.operators[key]}</MenuItem>
                ))}
              </Select>
            </div>

            <div className="ml-3 param">
              <div className="input-title">{terms.Filter.value}</div>
              {getValueInput(filter)}
            </div>

          </div>

        ))}
        <div className="d-flex justify-content-start align-items-start panel-footer mt-1 ml-2">
          <Button
            disabled={missingFilterValue}
            onClick={handleAddFilter}

          >
            {terms.Filter.add}
          </Button>
        </div>
      </div>
    </ClickAwayListener>
  )
}
